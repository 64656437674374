import {
  DateTimeInput,
  FilterButton,
  FilterForm,
  ListBase,
  TextField,
  TextInput,
  DateField,
  Datagrid,
  TopToolbar,
  BooleanField,
  SelectInput,
  BooleanInput,
} from 'react-admin'
import { TagsField, VirsiPagination } from '../components'
import { DATAGRID_SX, validateIsGuid } from '../utils'

const OAUTH_APP_KINDS = [
  { id: 'mobile_app', name: 'Mobile App' },
  { id: 'website', name: 'Website (virsi.lv)' },
  { id: 'middleware', name: 'Middleware' },
  { id: 'eps_refueling_service', name: 'EPS Refueling Service' },
  { id: 'admin_website', name: 'Admin Website' },
  { id: 'other', name: 'Other' },
]

/* eslint-disable react/jsx-key */
const RefuelingFilters = [
  <TextInput source="id$eq" label="ID" validate={validateIsGuid} />,
  <TextInput source="uid" label="UID" />,
  <SelectInput source="kind$eq" label="Kind" choices={OAUTH_APP_KINDS} />,
  <TextInput source="name" />,
  <TextInput source="scopes" />,
  <TextInput source="redirectUri" />,
  <BooleanInput source="confidential" defaultValue={true} />,
  <DateTimeInput source="createdAt$gte" label="Created from" />,
  <DateTimeInput source="createdAt$lte" label="Created till" />,
]
/* eslint-enable react/jsx-key */

const ListToolbar = () => (
  <TopToolbar sx={{ alignItems: 'center' }}>
    <FilterForm filters={RefuelingFilters} />
    <FilterButton filters={RefuelingFilters} />
  </TopToolbar>
)

export const OAuthApplicationList = () => (
  <ListBase sort={{ field: 'name', order: 'ASC' }}>
    <ListToolbar />
    <Datagrid rowClick="show" bulkActionButtons={false} sx={DATAGRID_SX}>
      <TextField source="kind" />
      <TextField source="name" />
      <TagsField source="scopes" />
      <TagsField source="scopesExcludedWhenNoUser" />
      <TextField source="uid" />
      <TextField source="redirectUri" />
      <BooleanField source="confidential" />
      <TextField source="id" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
    <VirsiPagination />
  </ListBase>
)
