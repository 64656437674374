import {
  TextField,
  DateField,
  Show,
  SimpleShowLayout,
  NumberField,
  ArrayField,
  Datagrid,
  TopToolbar,
  EditButton,
  useRecordContext,
} from 'react-admin'
import { LinkToRecordRequestLogs, ReceiptTextShowField, StationNameShowField } from '../components'
import { isEditable } from './utils'

const ReceiptShowActions = () => {
  const paymentRequest = useRecordContext()
  const button = paymentRequest && isEditable(paymentRequest) ? <EditButton /> : null
  return <TopToolbar>{button}</TopToolbar>
}

export const ReceiptShow = () => (
  <Show actions={<ReceiptShowActions />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="externalId" label="External ID" />
      <TextField source="state" />
      <TextField source="gasStationNfCode" label="Gas Station NF Code" />
      <StationNameShowField source="gasStation.nameTranslation" label="Gas Station Name" />
      <TextField source="paymentType" />
      <TextField source="endUserProvider" />
      <TextField source="chargingProvider" />
      <TextField source="chargingStationId" />
      <TextField source="connectorId" />
      <TextField source="ecrCode" />
      <TextField source="chargingSessionId" />
      <NumberField source="originalTotalAmountInCents" />
      <NumberField source="totalAmountInCents" />
      <NumberField source="totalVatAmountInCents" />
      <NumberField source="totalDiscountAmountInCents" />
      <TextField source="receiptNumber" />
      <TextField source="clientDetails" />
      <ReceiptTextShowField source="receiptText" />
      <ArrayField source="originalProducts" label="Original products (from SET)">
        <Datagrid bulkActionButtons={false}>
          <TextField source="code" />
          <TextField source="connectorType" />
          <TextField source="connectorPower" />
          <NumberField source="quantity" />
          <NumberField source="pricePerUnitInCents" label="Price per unit in cents (without discount)" />
          <NumberField source="amountInCents" label="Amount in cents (discounted)" />
          <NumberField source="discountAmountInCents" />
          <TextField source="unit" />
          <NumberField source="sequenceNumber" />
        </Datagrid>
      </ArrayField>
      <ArrayField source="products" label="Products (from ECR)">
        <Datagrid bulkActionButtons={false}>
          <TextField source="code" />
          <NumberField source="quantity" />
          <NumberField source="pricePerUnitInCents" label="Price per unit in cents (discounted)" />
          <NumberField source="amountInCents" label="Amount in cents (discounted)" />
          <NumberField source="discountAmountInCents" />
          <NumberField source="sequenceNumber" />
        </Datagrid>
      </ArrayField>
      <TextField source="lastErrorCode" />
      <TextField source="lastErrorMessage" />
      <DateField source="lastErrorAt" showTime />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
      <TextField source="manuallyEditedBy" />
      <LinkToRecordRequestLogs filterName="Receipt" />
    </SimpleShowLayout>
  </Show>
)
