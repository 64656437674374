export const PAYMENT_REQUEST_STATES = [
  { id: 'new', name: 'New' },
  { id: 'locked-by-ecr', name: 'Locked by ECR' },
  { id: 'failed', name: 'Failed (can be retried)' },
  { id: 'final-succeeded', name: 'Succeeded (final)' },
  { id: 'final-canceled', name: 'Canceled (not used, final)' },
  { id: 'final-uncharged', name: 'Uncharged (0 kWh, final)' },
  { id: 'must-handle-manually', name: 'Must handle manually' },
]

const PLAIN_EDITABLE_PAYMENT_REQUEST_STATES = ['must-handle-manually']
export const isEditable = (paymentRequest) => PLAIN_EDITABLE_PAYMENT_REQUEST_STATES.includes(paymentRequest.state)
