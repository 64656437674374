import {
  ArrayInput,
  Edit,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  email,
  required,
  useNotify,
  useRefresh,
  BooleanInput,
  AutocompleteArrayInput,
  useGetList,
  Loading,
} from 'react-admin'
import { buildErrorMessage } from '../utils'
import { EditToolbarWithoutDeleteButton } from '../components'

const validateEmail = [required(), email()]

type Station = {
  id: number
  nfCode: string
}

export const GlobalSettingEdit = () => {
  const { data: stations, isLoading } = useGetList<Station>('stations', {
    pagination: { page: 1, perPage: 10000 },
    sort: { field: 'nfCode', order: 'ASC' },
  })

  const notify = useNotify()
  const refresh = useRefresh()
  // TODO: Transform server-side attribute error messages properly
  const onError = (error) => {
    notify(buildErrorMessage(error), { type: 'error' })
    refresh()
  }
  if (isLoading) {
    return <Loading />
  }
  return (
    <Edit mutationMode="pessimistic" mutationOptions={{ onError }}>
      <SimpleForm toolbar={<EditToolbarWithoutDeleteButton />}>
        <ArrayInput
          source="sendMustHandleManuallyRefuelingEmailsToItems"
          label="Send must handle manually refueling emails to"
        >
          <SimpleFormIterator inline>
            <TextInput
              source="email"
              validate={validateEmail}
              helperText={false}
              fullWidth
              sx={{ '@media(min-width: 600px)': { minWidth: '300px' } }}
            />
          </SimpleFormIterator>
        </ArrayInput>
        <BooleanInput label="Mobile refueling disabled Globally" source="mobileRefuelingDisabled" />
        <AutocompleteArrayInput
          source="mobileRefuelingDisabledForStations"
          choices={stations}
          optionText="nfCode"
          optionValue="nfCode"
        />
      </SimpleForm>
    </Edit>
  )
}
