import {
  DateTimeInput,
  Edit,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useGetIdentity,
  useNotify,
  useRefresh,
} from 'react-admin'
import { RECEIPT_STATES } from './utils'
import { buildErrorMessage } from '../utils'
import { EditToolbarWithoutDeleteButton } from '../components'
import { useState } from 'react'

export const ReceiptEdit = () => {
  const notify = useNotify()
  const refresh = useRefresh()
  const { identity } = useGetIdentity()

  const transform = (data) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { originalProducts, products, gasStation, ...editedData } = data
    editedData.manuallyEditedBy = `${identity?.fullName} ${identity?.id}`
    return editedData
  }

  const onError = (error) => {
    notify(buildErrorMessage(error), { type: 'error' })
    refresh()
  }

  const [selectedState, setSelectedState] = useState('')

  return (
    <Edit mutationMode="pessimistic" transform={transform} mutationOptions={{ onError }} redirect="show">
      <SimpleForm toolbar={<EditToolbarWithoutDeleteButton />}>
        <SelectInput
          source="state"
          choices={RECEIPT_STATES}
          validate={required()}
          fullWidth={true}
          onChange={(event) => setSelectedState(event.target.value)}
        />
        {selectedState === 'succeeded' ? (
          <>
            <TextInput source="receiptNumber" required={true} fullWidth={true} />
            <TextInput source="receiptText" required={true} multiline={true} fullWidth={true} />
          </>
        ) : null}
        {selectedState === 'failed' ? (
          <>
            <NumberInput source="lastErrorCode" required={true} fullWidth={true} />
            <TextInput source="lastErrorMessage" required={true} fullWidth={true} />
            <DateTimeInput source="lastErrorAt" required={true} fullWidth={true} />
          </>
        ) : null}
      </SimpleForm>
    </Edit>
  )
}
