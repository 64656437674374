import { Box, IconButton, ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material'
import { AppBar, Layout, LayoutProps, Logout, useRedirect, UserMenu, useUserMenu } from 'react-admin'
import EditIcon from '@mui/icons-material/Edit'
import ClearIcon from '@mui/icons-material/Clear'

import logo from '../logo.svg'
import { forwardRef, RefObject } from 'react'

// It's important to pass the ref to allow MUI to manage the keyboard navigation
const PasswordChangeMenu = forwardRef((props, ref) => {
  const redirect = useRedirect()
  // const navigate = useNavigate()
  const { onClose } = useUserMenu()
  return (
    <MenuItem
      ref={ref as RefObject<HTMLLIElement>}
      // It's important to pass the props to allow MUI to manage the keyboard navigation
      {...props}
      sx={{ color: 'text.secondary' }}
      onClick={() => {
        redirect('/change-password')
        onClose()
      }}
    >
      <ListItemIcon>
        <EditIcon />
      </ListItemIcon>
      <ListItemText>Change password</ListItemText>
    </MenuItem>
  )
})
PasswordChangeMenu.displayName = 'PasswordChangeMenu'

const VirsiUserMenu = (props: any) => (
  <UserMenu {...props}>
    <Logout />
    <PasswordChangeMenu />
  </UserMenu>
)

const resetAllAppData = () => {
  if (!confirm('All saved filters will be gone. Are you sure you want to clear admin app state in Your browser?')) {
    return
  }

  localStorage.clear()
  window.location.href = '/'
}

const VirsiAppBar = (props: any) => (
  <AppBar {...props} color="secondary" userMenu={<VirsiUserMenu />}>
    <div style={{ padding: '5px' }}>
      <img src={logo} alt="logo" style={{ width: '112px', height: '35px' }} />
      <Typography
        variant="h5"
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          display: 'inline-block',
          paddingLeft: '10px',
          lineHeight: 1.1,
        }}
      >
        Admin
      </Typography>
    </div>
    <Box flex="1">
      {/* Hide currently because we have no page titles specified & "React Admin shown on errors here" */}
      {/* <Typography variant="h6" id="react-admin-title"></Typography> */}
    </Box>
    <IconButton color="inherit" onClick={resetAllAppData} title="Clear browser state">
      <ClearIcon />
    </IconButton>
  </AppBar>
)

const VirsiLayout = (props: LayoutProps) => {
  return <Layout {...props} appBar={VirsiAppBar} />
}
export default VirsiLayout
