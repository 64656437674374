import { BooleanField, DateField, NumberField, ReferenceField, Show, SimpleShowLayout, TextField } from 'react-admin'
import { MaskedField, PERSONAL_CODE_MASK } from '../components'

export const PersonalCodeUserShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <MaskedField source="personalCode" mask={PERSONAL_CODE_MASK} />
      <TextField source="givenName" />
      <TextField source="surname" />
      <TextField source="provider" />
      <DateField source="dateOfBirth" />
      <BooleanField source="dateOfBirthGuessedFromAge" />
      <ReferenceField reference="phone-number-users" source="linkedPhoneUserId" link="show" />
      <DateField source="linkedPhoneUserAt" showTime />
      <NumberField source="signInCount" />
      <DateField source="currentSignInAt" showTime />
      <DateField source="lastSignInAt" showTime />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </SimpleShowLayout>
  </Show>
)
