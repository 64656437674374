import {
  DateTimeInput,
  FilterButton,
  FilterForm,
  ListBase,
  NumberInput,
  TextField,
  TextInput,
  DateField,
  Datagrid,
  TopToolbar,
  NumberField,
  BooleanInput,
  BooleanField,
} from 'react-admin'
import { MaskedField, PERSONAL_CODE_MASK, VirsiPagination } from '../components'
import { DATAGRID_SX, validateIsGuid } from '../utils'

/* eslint-disable react/jsx-key */
const Filters = [
  <TextInput source="id$eq" label="ID" validate={validateIsGuid} />,
  <TextInput source="personalCode$cont" label="Personal code" />,
  <TextInput source="givenName$cont" label="Given name" />,
  <TextInput source="surname$cont" label="Surname" />,
  <BooleanInput source="dateOfBirthGuessedFromAge$eq" label="Date of birth guessed from age" />,
  <TextInput source="linkedPhoneUserId$eq" label="Linked phone user ID" validate={validateIsGuid} />,
  <DateTimeInput source="linkedPhoneUserAt$gte" label="Linked with phone user from" />,
  <DateTimeInput source="linkedPhoneUserAt$lte" label="Linked with phone user till" />,
  <NumberInput source="signInCount$gte" label="Signed in count from" />,
  <NumberInput source="signInCount$lte" label="Signed in count till" />,
  <DateTimeInput source="createdAt$gte" label="Created from" />,
  <DateTimeInput source="createdAt$lte" label="Created till" />,
]
/* eslint-enable react/jsx-key */

const ListToolbar = () => (
  <TopToolbar sx={{ alignItems: 'center' }}>
    <FilterForm filters={Filters} />
    <FilterButton filters={Filters} />
  </TopToolbar>
)

export const PersonalCodeUserList = () => (
  <ListBase sort={{ field: 'createdAt', order: 'DESC' }}>
    <ListToolbar />
    <Datagrid rowClick="show" bulkActionButtons={false} sx={DATAGRID_SX}>
      <TextField source="id" />
      <MaskedField source="personalCode" mask={PERSONAL_CODE_MASK} />
      <TextField source="givenName" />
      <TextField source="surname" />
      <TextField source="provider" />
      <DateField source="dateOfBirth" />
      <BooleanField source="dateOfBirthGuessedFromAge" label="D.O.B from age" />
      <DateField source="linkedPhoneUserAt" showTime />
      <NumberField source="signInCount" />
      <DateField source="currentSignInAt" showTime />
      <DateField source="lastSignInAt" showTime />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
    <VirsiPagination />
  </ListBase>
)
