import {
  DateTimeInput,
  FilterButton,
  FilterForm,
  ListBase,
  NumberInput,
  TextField,
  TextInput,
  DateField,
  Datagrid,
  FunctionField,
  TopToolbar,
  NumberField,
  SelectArrayInput,
  ExportButton,
  EditButton,
} from 'react-admin'
import { TagsField, VirsiPagination } from '../components'
import { DATAGRID_SX, pickTranslation, validateIsGuid } from '../utils'
import { REFUELING_STATES, PLAIN_EDITABLE_REFUELING_STATES } from './utils'

/* eslint-disable react/jsx-key */
const RefuelingFilters = [
  <NumberInput source="id" label="Refueling ID" />,
  <TextInput source="externalId$eq" label="External ID" validate={validateIsGuid} />,
  <SelectArrayInput source="state$in" choices={REFUELING_STATES} label="State" />,
  <TextInput source="stationNfCode" label="Station NF Code" />,
  <DateTimeInput source="createdAt$gte" label="Created From" />,
  <DateTimeInput source="createdAt$lte" label="Created Till" />,
  <TextInput source="loyaltyCardNumber$cont" label="Loyalty Card NR" />,
  <TextInput source="cardNumberForEps$cont" label="Card Number For EPS" />,
  <TextInput type="text" source="bankCard.number$cont" label="Bank Card" />,
  <TextInput source="productName$cont" label="Product Name" />,
  <TextInput source="productCode" />,
  <TextInput source="receiptNumber$cont" label="Receipt Number" />,
  <TextInput source="lastErrorMessage$cont" label="Last Error Message" />,
  <NumberInput source="lastErrorCode" label="Last Error Code" />,
  <DateTimeInput source="lastErrorAt$gte" label="Last Error From" />,
  <DateTimeInput source="lastErrorAt$lte" label="Last Error Till" />,
  <NumberInput source="totalCost$gte" label="Total Cost From" />,
  <NumberInput source="totalCost$lte" label="Total Cost Till" />,
  <NumberInput source="reservedAmount$gte" label="Reserved Amount From" />,
  <NumberInput source="reservedAmount$lte" label="Reserved Amount Till" />,
  <NumberInput source="userId" label="User ID" />,
]
/* eslint-enable react/jsx-key */

const ListToolbar = () => (
  <TopToolbar sx={{ alignItems: 'center' }}>
    <FilterForm filters={RefuelingFilters} />
    <FilterButton filters={RefuelingFilters} />
    <ExportButton />
  </TopToolbar>
)

export const RefuelingList = () => (
  <ListBase sort={{ field: 'id', order: 'DESC' }}>
    <ListToolbar />
    <Datagrid rowClick="show" bulkActionButtons={false} sx={DATAGRID_SX}>
      <TextField source="id" />
      <TextField source="externalId" label="External ID" />
      <TextField source="state" />
      <FunctionField
        label="Station"
        source="station.nameTranslation"
        sortable={false}
        render={(record) =>
          record.station
            ? `${pickTranslation(record.station.nameTranslation)} (${record.station.nfCode})`
            : record.stationNfCode
        }
      />
      <TextField source="productName" />
      <FunctionField
        source="productName"
        label="Product Name (Code)"
        sortable
        render={(record) => `${record.productName} (${record.productCode})`}
      />
      <TextField source="lastErrorCode" />
      <TextField source="lastErrorMessage" />
      <DateField source="lastErrorAt" showTime />
      <DateField source="createdAt" showTime />
      <TextField source="cardNumberForEps" />
      <FunctionField
        source="maxQuantityValue"
        label="Max Quantity"
        sortable
        render={(record) => `${record.maxQuantityValue} ${record.maxQuantityUnit}`}
      />
      <NumberField source="quantity" />
      <NumberField source="totalCost" />
      <NumberField source="listPrice" />
      <NumberField source="reservedAmount" />
      <TextField source="receiptNumber" />
      <NumberField source="totalCostWithoutVat" />
      <NumberField source="totalVat" />
      <NumberField source="vatRate" />
      <NumberField source="discountedUnitPrice" />
      <NumberField source="unitDiscount" />
      <NumberField source="totalDiscount" />
      <DateField source="purchaseTime" showTime />
      <TextField source="externalTransactionId" />
      <TextField source="controllerId" />
      <TextField source="nozzleId" />
      <TextField source="externalLoyaltyProfileId" />
      <TextField source="loyaltyCardNumber" />
      <TextField source="bankCard.number" />
      <TextField source="fleetCard.number" />
      <TextField source="sebPayment.finalAmount" />
      <TextField source="userId" />
      <TextField source="email" />
      <TagsField source="stationTerminalAliases" />
      <DateField source="updatedAt" showTime />
      <FunctionField
        label=""
        render={(record) => {
          if (PLAIN_EDITABLE_REFUELING_STATES.includes(record.state)) {
            return <EditButton />
          } else {
            return null
          }
        }}
      />
    </Datagrid>
    <VirsiPagination />
  </ListBase>
)
