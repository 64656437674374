export const REFUELING_STATES = [
  { id: 'new', name: 'New' },
  { id: 'reserving-in-bank', name: 'Reserving In Bank' },
  { id: 'reserved-in-bank', name: 'Reserved In Bank' },
  { id: 'submitted', name: 'Submitted' },
  { id: 'fueling', name: 'Fueling' },
  { id: 'completing-reservation-in-bank', name: 'Completing Reservation In Bank' },
  { id: 'completed', name: 'Completed' },
  { id: 'completed-without-receipt', name: 'Completed Without Receipt' },
  { id: 'canceling-reservation-in-bank', name: 'Canceling Reservation In Bank' },
  { id: 'canceled', name: 'Canceled' },
  { id: 'manually-handled', name: 'Manually Handled' },
  { id: 'must-handle-manually', name: 'Must Handle Manually' },
]

export const EDITABLE_REFUELING_STATES = [
  { id: 'manually-handled', name: 'Manually Handled' },
  { id: 'must-handle-manually', name: 'Must Handle Manually' },
]

export const PLAIN_EDITABLE_REFUELING_STATES = ['manually-handled', 'must-handle-manually']
