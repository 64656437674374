import { ListBase, Datagrid, useRedirect, useRecordContext, PublicFieldProps } from 'react-admin'
import { VirsiPagination } from '../components'
import { FC } from 'react'

const RedirectField: FC<PublicFieldProps> = () => {
  const redirect = useRedirect()
  const record = useRecordContext()
  if (record) {
    setTimeout(() => redirect('show', 'global-settings', record.id), 10)
  }
  return null
}

export const GlobalSettingList = () => (
  <ListBase>
    <Datagrid rowClick="show" bulkActionButtons={false} style={{ display: 'none' }}>
      <RedirectField />
    </Datagrid>
    <VirsiPagination />
  </ListBase>
)
