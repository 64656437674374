import { PublicFieldProps, useRecordContext } from 'react-admin'

export interface TagsFieldProps extends PublicFieldProps {
  itemValueField?: string
}

export const TagsField = (props: TagsFieldProps) => {
  const record = useRecordContext()
  if (!record || !props.source) {
    return null
  }
  const items = record[props.source]
  if (Array.isArray(items)) {
    return (
      <ul style={{ paddingLeft: 16, margin: 0, fontSize: '0.875rem', whiteSpace: 'nowrap' }}>
        {(items || []).map((item) => {
          let value = item
          if (props.itemValueField) {
            value = item[props.itemValueField]
          }
          return <li key={value}>{value}</li>
        })}
      </ul>
    )
  } else if (items === null || items === undefined) {
    return items
  } else {
    // There are cases in production data when object (map) is returned instead of array
    return JSON.stringify(items)
  }
}
