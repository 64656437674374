export const RECEIPT_STATES = [
  { id: 'new', name: 'New' },
  { id: 'sent-to-ecr', name: 'Sent to ECR' },
  { id: 'printing-at-ecr', name: 'Printing at ECR' },
  { id: 'succeeded', name: 'Succeeded' },
  { id: 'failed', name: 'Failed' },
  { id: 'uncharged', name: 'Uncharged (0 kWh)' },
  { id: 'must-handle-manually', name: 'Must handle manually' },
]

export const RECEIPT_PAYMENT_TYPES = [
  { id: 1, name: 'Mobilly' },
  { id: 2, name: 'Easy2Charge' },
  { id: 3, name: 'SNABB' },
  { id: 4, name: 'RFID' },
  { id: 5, name: 'Virsi mobile application' },
]

export const RECEIPT_END_USER_PROVIDERS = [
  { id: 'mobilly', name: 'Mobilly' },
  { id: 'easy2charge', name: 'Easy2Charge' },
  { id: 'snabb', name: 'SNABB' },
  { id: 'rfid', name: 'RFID' },
  { id: 'virsi-mob-app', name: 'Virsi mobile application' },
]

const PLAIN_EDITABLE_RECEIPT_STATES = ['must-handle-manually']
export const isEditable = (receipt) => PLAIN_EDITABLE_RECEIPT_STATES.includes(receipt.state)
