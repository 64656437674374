import { DateField, Show, SimpleShowLayout, BooleanField } from 'react-admin'
import { TagsField } from '../components'

export const GlobalSettingShow = () => (
  <Show>
    <SimpleShowLayout>
      <TagsField
        source="sendMustHandleManuallyRefuelingEmailsToItems"
        itemValueField="email"
        label="Send must handle manually refueling emails to"
      />
      <BooleanField source="mobileRefuelingDisabled" label="Mobile refueling disabled Globally" />

      <TagsField source="mobileRefuelingDisabledForStations" label="Disabled refueling for stations" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </SimpleShowLayout>
  </Show>
)
