import { Link } from '@mui/material'
import { Login, LoginForm } from 'react-admin'

export const VirsiLoginPage = (props) => (
  <Login {...props}>
    <LoginForm {...props}></LoginForm>
    <div style={{ margin: '0 1em 1em 1em', display: 'flex' }}>
      <Link href={`${process.env.REACT_APP_AUTH_URL}/admin/users/password/new`} sx={{ fontSize: '0.8rem' }}>
        Forgot password?
      </Link>
    </div>
  </Login>
)
