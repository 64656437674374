import {
  DateTimeInput,
  FilterButton,
  FilterForm,
  ListBase,
  NumberInput,
  TextField,
  TextInput,
  DateField,
  Datagrid,
  FunctionField,
  TopToolbar,
  NumberField,
  SelectArrayInput,
  ExportButton,
  PublicFieldProps,
  useRecordContext,
  EditButton,
} from 'react-admin'
import { Color, VirsiPagination } from '../components'
import { DATAGRID_SX, pickTranslation, validateIsGuid } from '../utils'
import { RECEIPT_PAYMENT_TYPES, RECEIPT_STATES, RECEIPT_END_USER_PROVIDERS, isEditable } from './utils'
import { FC } from 'react'
import { Chip } from '@mui/material'

/* eslint-disable react/jsx-key */
const ReceiptFilters = [
  <NumberInput source="id" label="Internal ID" />,
  <TextInput source="externalId$eq" label="External ID" validate={validateIsGuid} />,
  <SelectArrayInput source="state$in" choices={RECEIPT_STATES} label="State" />,
  <SelectArrayInput source="paymentType$in" choices={RECEIPT_PAYMENT_TYPES} label="Payment type" />,
  <SelectArrayInput source="endUserProvider$in" choices={RECEIPT_END_USER_PROVIDERS} label="End user provider" />,
  <TextInput source="gasStationNfCode" label="Gas Station NF Code" />,
  <DateTimeInput source="createdAt$gte" label="Created From" />,
  <DateTimeInput source="createdAt$lte" label="Created Till" />,
  // <TextInput source="productCode" />, // TODO: productCode is in a jsonb field, can not be filtered natively
  <TextInput source="receiptNumber$cont" label="Receipt Number" />,
  <TextInput source="lastErrorMessage$cont" label="Last Error Message" />,
  <NumberInput source="lastErrorCode" label="Last Error Code" />,
  <DateTimeInput source="lastErrorAt$gte" label="Last Error From" />,
  <DateTimeInput source="lastErrorAt$lte" label="Last Error Till" />,
  <NumberInput source="originalTotalAmountInCents$gte" label="Amount From" />,
  <NumberInput source="originalTotalAmountInCents$lte" label="Amount Till" />,
]
/* eslint-enable react/jsx-key */

const STATE_COLOR_MAP: { [key: string]: Color } = {
  new: 'primary',
  'sent-to-ecr': 'warning',
  'printing-at-ecr': 'info',
  succeeded: 'success',
  failed: 'error',
  uncharged: 'default',
  'must-handle-manually': 'error',
}

const StateField: FC<PublicFieldProps> = () => {
  const record = useRecordContext()
  if (!record) {
    return null
  }
  const state = record.state
  const color: Color = STATE_COLOR_MAP[state] || 'default'
  const label = RECEIPT_STATES.find((s) => s.id === state)?.name || state
  return <Chip color={color} label={label} size="small" variant="outlined" />
}

const ListToolbar = () => (
  <TopToolbar sx={{ alignItems: 'center' }}>
    <FilterForm filters={ReceiptFilters} />
    <FilterButton filters={ReceiptFilters} />
    <ExportButton />
  </TopToolbar>
)

export const ReceiptList = () => (
  <ListBase sort={{ field: 'id', order: 'DESC' }}>
    <ListToolbar />
    <Datagrid rowClick="show" bulkActionButtons={false} sx={DATAGRID_SX}>
      <TextField source="id" />
      <TextField source="externalId" label="External ID" />
      <StateField source="state" />
      <FunctionField
        label="Gas Station"
        source="gasStation.nameTranslation"
        sortable={false}
        render={(record) =>
          record.gasStation
            ? `${pickTranslation(record.gasStation.nameTranslation)} (${record.gasStation.nfCode})`
            : record.gasStationNfCode
        }
      />
      <TextField source="paymentType" />
      <TextField source="endUserProvider" />
      <TextField source="chargingStationId" />
      <TextField source="connectorId" />
      <TextField source="ecrCode" />
      <TextField source="chargingSessionId" />
      <NumberField source="originalTotalAmountInCents" label="Amount in cents" />
      <TextField source="receiptNumber" />
      <TextField source="clientDetails" />
      <TextField source="lastErrorCode" />
      <TextField source="lastErrorMessage" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
      <FunctionField
        label=""
        render={(record) => {
          if (isEditable(record)) {
            return <EditButton />
          } else {
            return null
          }
        }}
      />
    </Datagrid>
    <VirsiPagination />
  </ListBase>
)
