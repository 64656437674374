import { styled } from '@mui/material'

export const StyledPre = styled('pre')(
  ({ theme }) => `
  margin-top: 0;
  font-size: ${theme.typography.fontSize}px;
  line-height: 1;
  max-width: 80vw;
  white-space: break-spaces;
`,
)
