import { BooleanField, DateField, Show, SimpleShowLayout, TextField } from 'react-admin'
import { TagsField } from '../components'

export const OAuthApplicationShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="kind" />
      <TextField source="name" />
      <TagsField source="scopes" />
      <TagsField source="scopesExcludedWhenNoUser" />
      <TextField source="uid" />
      <TextField source="redirectUri" />
      <BooleanField source="confidential" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </SimpleShowLayout>
  </Show>
)
