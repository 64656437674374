import { Typography } from '@mui/material'
import {
  TextField,
  DateField,
  Datagrid,
  Show,
  SimpleShowLayout,
  ReferenceManyField,
  useRecordContext,
  WrapperField,
  EditButton,
  TopToolbar,
} from 'react-admin'
import {
  LinkToRecordRequestLogs,
  ReceiptTextShowField,
  StationNameShowField,
  TagsField,
  VirsiPagination,
} from '../components'
import { PLAIN_EDITABLE_REFUELING_STATES } from './utils'

const FleetCardField = (props) => {
  const { fleetCard } = useRecordContext(props)
  return fleetCard ? <Typography variant="body2">{fleetCard.number}</Typography> : null
}

const BankCardField = (props) => {
  const { bankCard } = useRecordContext(props)
  return bankCard ? <Typography variant="body2">{bankCard.number}</Typography> : null
}

const RefuelingShowActions = () => {
  const refueling = useRecordContext()
  const button = refueling && PLAIN_EDITABLE_REFUELING_STATES.includes(refueling.state) ? <EditButton /> : null
  return <TopToolbar>{button}</TopToolbar>
}

export const RefuelingShow = () => (
  <Show actions={<RefuelingShowActions />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="externalId" label="External ID" />
      <TextField source="state" />
      <TextField source="stationNfCode" />
      <StationNameShowField source="station.nameTranslation" label="Station name" />
      <TagsField source="stationTerminalAliases" />
      <TextField source="controllerId" />
      <TextField source="nozzleId" />
      <TextField source="productCode" />
      <TextField source="productName" />
      <TextField source="maxQuantityUnit" />
      <TextField source="maxQuantityValue" />
      <TextField source="reservedAmount" />
      <TextField source="lastErrorCode" />
      <TextField source="lastErrorMessage" />
      <DateField source="lastErrorAt" showTime />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
      <TextField source="externalLoyaltyProfileId" />
      <TextField source="loyaltyCardNumber" />
      <TextField source="cardNumberForEps" />
      <TextField source="externalTransactionId" />
      <TextField source="receiptNumber" />
      <ReceiptTextShowField source="receiptText" />
      <TextField source="email" label="E-mail (for receipt)" />
      <TextField source="quantity" />
      <TextField source="totalCost" />
      <TextField source="totalCostWithoutVat" />
      <TextField source="totalVat" />
      <TextField source="vatRate" />
      <TextField source="discountedUnitPrice" />
      <TextField source="listPrice" />
      <TextField source="unitDiscount" />
      <TextField source="totalDiscount" />
      <DateField source="purchaseTime" showTime />
      <BankCardField source="bankCard" />
      <FleetCardField source="fleetCard" />
      <WrapperField label="Seb Payment" sortBy="sebPayment.externalId">
        <TextField source="sebPayment.externalId" />
        <br />
        <TextField source="sebPayment.orderReference" />
        <br />
        <TextField source="sebPayment.finalAmount" />
      </WrapperField>
      <TextField source="userId" />
      <LinkToRecordRequestLogs filterName="Refueling" />
      <ReferenceManyField
        label={
          <Typography component="span" variant="h5">
            History
          </Typography>
        }
        reference="refueling-log"
        target="refuelingId"
        pagination={<VirsiPagination />}
      >
        <Datagrid bulkActionButtons={false}>
          <TextField source="id" />
          <TextField source="state" />
          <TextField source="lastErrorCode" />
          <TextField source="lastErrorMessage" />
          <DateField source="at" showTime />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
)
