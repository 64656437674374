import { FC } from 'react'
import { Typography, Link as MuiLink, Chip } from '@mui/material'
import { stringify } from 'qs'
import {
  BooleanField,
  Datagrid,
  DateField,
  Link,
  ListContextProvider,
  PublicFieldProps,
  ReferenceOneField,
  Show,
  SimpleShowLayout,
  TextField,
  useCreatePath,
  useList,
  useRecordContext,
} from 'react-admin'
import { Color, StyledPre, TagsField } from '../components'
import { tryFormat } from '../utils'

const CardByStateField: FC<PublicFieldProps> = (props: PublicFieldProps) => {
  const record = useRecordContext()
  if (!record) {
    return null
  }
  let color: Color = 'default'
  if (['approved', 'active'].includes(record.state)) {
    color = 'success'
  } else if (record.state === 'waiting_for_approval') {
    color = 'warning'
  } else if (record.state === 'deleted') {
    color = 'error'
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return <Chip label={record[props.source!]} color={color} size="small" variant="outlined" />
}

const FleetCardsList = () => {
  const user = useRecordContext()
  const itemsContext = useList({ data: user?.fleetCards ?? [] })
  if (!user) {
    return null
  }
  // TODO: use this instead:
  // <ReferenceManyField reference="fleetCards" source="id" target="UserId">
  return (
    <ListContextProvider value={itemsContext}>
      <Typography variant="h5">Fleet cards</Typography>
      <Datagrid bulkActionButtons={false} sx={{ maxWidth: 'calc(100vw - 300px)', overflowX: 'auto' }}>
        <TextField source="id" />
        <CardByStateField source="number" />
        <TextField source="name" />
        <CardByStateField source="state" />
        <DateField source="deletedOnMobileDeviceMismatchAt" showTime />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
      </Datagrid>
    </ListContextProvider>
  )
}

const BankCardsList = () => {
  const user = useRecordContext()
  const itemsContext = useList({ data: user?.bankCards ?? [] })
  if (!user) {
    return null
  }
  return (
    <ListContextProvider value={itemsContext}>
      <Typography variant="h5">Bank cards</Typography>
      <Datagrid bulkActionButtons={false} sx={{ maxWidth: 'calc(100vw - 300px)', overflowX: 'auto' }}>
        <TextField source="id" />
        <CardByStateField source="number" />
        <TextField source="name" />
        <CardByStateField source="state" />
        <DateField source="deletedOnMobileDeviceMismatchAt" showTime />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
      </Datagrid>
    </ListContextProvider>
  )
}

const RefuelingsList = () => {
  const user = useRecordContext()
  const itemsContext = useList({ data: user?.refuelings ?? [] })
  const createPath = useCreatePath()
  if (!user) {
    return null
  }
  let url = createPath({ resource: 'refueling', type: 'list' })
  const query = stringify({
    displayedFilters: JSON.stringify({ userId: true }),
    filter: JSON.stringify({ userId: user.id }),
    order: 'DESC',
    sort: 'id',
    page: 1,
  })
  url = `${url}?${query}`
  return (
    <ListContextProvider value={itemsContext}>
      <Link to={url}>
        <Typography variant="h5">Refuelings (last 5)</Typography>
      </Link>
      <Datagrid bulkActionButtons={false} sx={{ maxWidth: 'calc(100vw - 300px)', overflowX: 'auto' }}>
        <TextField source="id" />
        <TextField source="externalId" label="Refueling ID" />
        <TextField source="state" />
        <TextField source="stationNfCode" />
        <TextField source="controllerId" />
        <TextField source="nozzleId" />
        <TextField source="productCode" />
        <TextField source="productName" />
        <TextField source="maxQuantityUnits" />
        <TextField source="maxQuantityValue" />
        <TextField source="reservedAmount" />
        <TextField source="lastErrorCode" />
        <TextField source="lastErrorMessage" />
        <DateField source="lastErrorAt" showTime />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
        <TextField source="externalLoyaltyProfileId" />
        <TextField source="loyaltyCardNumber" />
        <TextField source="externalTransactionId" />
        <TextField source="receiptNumber" />
        <TextField source="email" label="E-mail (for receipt)" />
        <TextField source="quantity" />
        <TextField source="totalCost" />
        <TextField source="totalCostWithoutVat" />
        <TextField source="totalVat" />
        <TextField source="vatRate" />
        <TextField source="discountedUnitPrice" />
        <TextField source="listPrice" />
        <TextField source="unitDiscount" />
        <TextField source="totalDiscount" />
        <DateField source="purchaseTime" showTime />
        <TextField source="bankCardId" />
        <TextField source="fleetCardId" />
        <TextField source="sebPaymentId" />
      </Datagrid>
    </ListContextProvider>
  )
}

const RequestLogsLink = () => {
  const user = useRecordContext()
  const createPath = useCreatePath()
  if (!user) {
    return null
  }
  let url = createPath({ resource: 'request-logs', type: 'list' })
  const query = stringify({
    displayedFilters: JSON.stringify({ filterableAttributesPhone$eq: true }),
    filter: JSON.stringify({ filterableAttributesPhoneUserId$eq: user.externalId }),
    order: 'DESC',
    sort: 'id',
    page: 1,
  })
  url = `${url}?${query}`
  return (
    <Link to={url}>
      <Typography variant="h5">Request logs</Typography>
    </Link>
  )
}

const buildProfileUrlInLoyalty = (user) => {
  const jsonText = user.loyaltyProfileJson
  if (!jsonText) {
    return null
  }
  const json = JSON.parse(jsonText)
  const profileId = json.data?.id
  if (!profileId) {
    return null
  }
  // TODO: Get link from backend:
  return `${process.env.REACT_APP_EPS_URL}/Clients/Clients/Details/${profileId}`
}

const LoyaltyData = () => {
  const user = useRecordContext()
  if (!user) {
    return null
  }
  let header = <Typography variant="h5">Loyalty (EPS) data</Typography>
  const url = buildProfileUrlInLoyalty(user)
  if (url) {
    header = (
      <MuiLink href={url} target="_blank" rel="noopener">
        {header}
      </MuiLink>
    )
  }
  return (
    <>
      {header}
      <StyledPre>{tryFormat(user.loyaltyProfileJson)}</StyledPre>
    </>
  )
}

const DefaultPaymentCardField = () => {
  const record = useRecordContext()
  if (!record) return null

  const cards = (record.defaultPaymentCardType === 'fleet' ? record.fleetCards : record.bankCards) || []
  const card = cards.find((card) => card.id === record.defaultPaymentCardId)
  return card ? <>{card.number}</> : null
}
DefaultPaymentCardField.defaultProps = { label: 'Default payment card' }

export const PhoneNumberUserShow = () => (
  <Show>
    <SimpleShowLayout>
      <Typography variant="h5">OAuth User</Typography>
      <TextField source="id" />
      <TextField source="phone" />
      <DateField source="lockedAt" showTime />
      <TextField source="failedAttempts" />
      <DateField source="smsCodeSentAt" showTime />
      <TextField source="signInCount" />
      <DateField source="currentSignInAt" showTime />
      <DateField source="lastSignInAt" showTime />
      <DateField source="mobileLoyaltyTokenRetrievedAt" showTime />
      <DateField source="webLoyaltyTokenRetrievedAt" showTime />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
      <ReferenceOneField
        label="Linked personal code user"
        reference="personal-code-users"
        target="linkedPhoneUserId"
        link="show"
      ></ReferenceOneField>
      <ReferenceOneField label="" reference="users" target="externalId">
        <SimpleShowLayout sx={{ marginLeft: '-16px' }}>
          <Typography variant="h5">Middleware User</Typography>
          <TextField source="id" />
          <TextField source="phoneNumber" />
          <TextField source="language" />
          <TextField source="defaultPaymentCardType" />
          <DefaultPaymentCardField />
          <TextField source="mobileDeviceId" label="Mobile device identifier" />
          <BooleanField source="mobileDeviceIOs" label="Mobile device with iOS" />
          <DateField source="deviceRegisteredOnMsDynamicAt" label="Device registered on MS Dynamics CRM at" showTime />
          <TextField source="registrationStatus" label="MS Dynamics device registration status" />
          <TextField source="registrationFailureReason" label="MS Dynamics device registration failure reason" />
          <TextField source="registrationRequestId" label="MS Dynamics device registration request ID" />
          <TagsField source="readPromotionIds" label="Read promotion IDs" />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
        </SimpleShowLayout>
        <br />
        <FleetCardsList />
        <br />
        <BankCardsList />
        <br />
        <RefuelingsList />
        <br />
        <RequestLogsLink />
        <br />
        <LoyaltyData />
      </ReferenceOneField>
    </SimpleShowLayout>
  </Show>
)
