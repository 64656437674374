import dayjs from 'dayjs'
import OAuthService from './OAuthService'
import { OAuthTokenData } from './utils'

const OAUTH_TOKEN_STORAGE_KEY = 'virsi-api-token'

export default class OAuthTokenStore {
  static retrievingToken = false

  static hasActiveToken() {
    const token = this.readToken()
    return token && this.isActive(token)
  }

  static async retrieveNewTokenIfMissing() {
    while (this.retrievingToken) {
      await new Promise((resolve) => setTimeout(resolve, 100))
    }
    if (this.hasActiveToken()) {
      return this.readToken() as OAuthTokenData
    }
    this.retrievingToken = true
    try {
      const token = await new OAuthService().retrieveToken()
      this.save(token)
      return token
    } finally {
      this.retrievingToken = false
    }
  }

  static async getTokenValue() {
    const token = this.readToken() || (await this.retrieveNewTokenIfMissing())
    return token.value
  }

  static clear() {
    localStorage.removeItem(OAUTH_TOKEN_STORAGE_KEY)
  }

  private static readToken() {
    const token = localStorage.getItem(OAUTH_TOKEN_STORAGE_KEY)
    if (token) {
      return JSON.parse(token) as OAuthTokenData
    } else {
      return null
    }
  }

  private static save(token: OAuthTokenData) {
    localStorage.setItem(OAUTH_TOKEN_STORAGE_KEY, JSON.stringify(token))
  }

  private static isActive(token: OAuthTokenData) {
    return dayjs().isBefore(dayjs(token.expiresAt))
  }
}
