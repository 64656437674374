import { combineDataProviders, DataProvider, fetchUtils, GetListParams, Options } from 'react-admin'
import crudProvider from 'ra-data-nestjsx-crud'
import OAuthTokenStore from '../authProvider/OAuthTokenStore'
import { fetchFromAuth } from '../authProvider/utils'

const isHash = (value) => value !== null && typeof value === 'object' && !Array.isArray(value)

// ra-data-nestjsx-crud offers specifying specific filter operations for fields
// using syntax: <TextInput source="id||$contL" />
// https://github.com/rayman1104/ra-data-nestjsx-crud/issues/26#issuecomment-1058333746
// But filter field becomes unchangeable when "source" property contains "|" symbol
// Not sure if this is some library version incompatibility or we're doing something wrong.
// Therefore this work-around transforms "id$contL" to "id||$contL" only in filter props.
const transformFilterKeys = (params: GetListParams) => {
  const reduceFunction = (hash, [key, value]) => {
    // For relational fetch filters check again to reduce value
    if (isHash(value)) {
      value = Object.entries(value).reduce(reduceFunction, {})
    }

    hash[key.replace('$', '||$')] = value
    return hash
  }
  params.filter = Object.entries(params.filter).reduce(reduceFunction, {})
}

// Possible operators:
// https://github.com/nestjsx/crud/wiki/Requests#filter-conditions
// $eq (=, equal)
// $ne (!=, not equal)
// $gt (>, greater than)
// $lt (<, lower that)
// $gte (>=, greater than or equal)
// $lte (<=, lower than or equal)
// $starts (LIKE val%, starts with)
// $ends (LIKE %val, ends with)
// $cont (LIKE %val%, contains)
// $excl (NOT LIKE %val%, not contains)
// $in (IN, in range, accepts multiple values)
// $notin (NOT IN, not in range, accepts multiple values)
// $isnull (IS NULL, is NULL, doesn't accept value)
// $notnull (IS NOT NULL, not NULL, doesn't accept value)
// $between (BETWEEN, between, accepts two values)
// $eqL (LOWER(field) =, equal)
// $neL (LOWER(field) !=, not equal)
// $startsL (LIKE|ILIKE val%)
// $endsL (LIKE|ILIKE %val, ends with)
// $contL (LIKE|ILIKE %val%, contains)
// $exclL (NOT LIKE|ILIKE %val%, not contains)
// $inL (LOWER(field) IN, in range, accepts multiple values)
// $notinL (LOWER(field) NOT IN, not in range, accepts multiple values)

const httpClientWithToken = async (url: string, options: Options = {}) => {
  const headers = new Headers(options.headers)
  headers.has('Accept') || headers.set('Accept', 'application/json')
  if (!headers.has('Authorization')) {
    const tokenValue = await OAuthTokenStore.getTokenValue()
    headers.set('Authorization', `Bearer ${tokenValue}`)
  }
  return fetchUtils.fetchJson(url, { ...options, headers })
}

const patchedProvider = (apiUrl: string, httpClient = httpClientWithToken) => {
  const provider: DataProvider = crudProvider(apiUrl, httpClient)
  return {
    ...provider,
    getList: async (resource: string, params: GetListParams) => {
      transformFilterKeys(params)
      return provider.getList(resource, params)
    },
  }
}

const apiProvider = patchedProvider(process.env.REACT_APP_API_URL as string)
const authProvider = patchedProvider(`${process.env.REACT_APP_AUTH_URL}/admin`, fetchFromAuth)

export const virsiCrudProvider = combineDataProviders((resource) => {
  switch (resource) {
    case 'phone-number-users':
    case 'personal-code-users':
    case 'admin-users':
    case 'users/current':
    case 'oauth-applications':
      return authProvider
    default:
      return apiProvider
  }
})
