import { Typography } from '@mui/material'
import { stringify } from 'qs'
import { Link, useCreatePath, useRecordContext } from 'react-admin'

const buildRequestLogsLink = (
  recordCreatedAt: string,
  recordExternalId: string,
  filterName: string,
  createPath: any,
) => {
  const startedAtFrom = new Date(recordCreatedAt)
  const startedAtTo = new Date(recordCreatedAt)
  startedAtFrom.setMinutes(startedAtFrom.getMinutes() - 5)
  startedAtTo.setMinutes(startedAtTo.getMinutes() + 5 * 60)
  const url = createPath({ resource: 'request-logs', type: 'list' })
  const query = stringify({
    displayedFilters: JSON.stringify({
      [`filterableAttributes${filterName}$eq`]: true,
      startedAt$gte: true,
      startedAt$lte: true,
    }),
    filter: JSON.stringify({
      [`filterableAttributes${filterName}Id$eq`]: recordExternalId,
      startedAt$gte: startedAtFrom,
      startedAt$lte: startedAtTo,
    }),
    order: 'DESC',
    sort: 'id',
    page: 1,
  })
  return `${url}?${query}`
}

export const LinkToRecordRequestLogs = (props: { filterName: string }) => {
  const record = useRecordContext()
  const createPath = useCreatePath()
  if (!record) {
    return null
  }
  const url = buildRequestLogsLink(record.createdAt, record.externalId, props.filterName, createPath)
  return (
    <Link to={url}>
      <Typography variant="h5">Request logs</Typography>
    </Link>
  )
}
