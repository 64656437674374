import { AuthProvider, HttpError, Options, UserIdentity } from 'react-admin'
import CsrfStore from './CsrfStore'
import OAuthTokenStore from './OAuthTokenStore'
import { fetchFromAuth } from './utils'

// https://marmelab.com/react-admin/Authentication.html
// https://marmelab.com/react-admin/doc/3.16/Authentication.html
export class VirsiAuthProvider implements AuthProvider {
  // Send username and password to the auth server and get back credentials
  async login({ username, password }) {
    const request: Options = {
      method: 'POST',
      body: JSON.stringify({
        admin_user: {
          email: username,
          password,
        },
      }),
    }
    try {
      const response = await fetchFromAuth('/admin/users/sign_in', request)
      CsrfStore.clear() // User session & csrf token should have changes
      return this.toUserIdentity(response.json)
    } catch (e) {
      const errorMessage = e instanceof HttpError ? e.body?.error : null
      if (errorMessage) {
        throw new Error(errorMessage)
      } else {
        const errorText = (e as Error).toString()
        throw new Error(`Internal server error. Sorry for inconvenience. ${errorText}`)
      }
    }
  }

  // Remove local credentials and notify the auth server that the user logged out
  async logout() {
    if (OAuthTokenStore.hasActiveToken()) {
      OAuthTokenStore.clear()
    }
    if (await this.userSessionIsAliveAtAuthServer()) {
      CsrfStore.clear() // Sometimes old csrf breaks logout. Should figure out why & remove this line.
      await fetchFromAuth('/admin/users/sign_out', { method: 'DELETE' })
    }
    CsrfStore.clear() // User session & csrf token should have changes
  }

  // When the user navigates, make sure that their credentials are still valid
  async checkAuth() {
    if (OAuthTokenStore.hasActiveToken()) {
      return
    }
    if (!(await this.userSessionIsAliveAtAuthServer())) {
      throw new Error('Not authenticated')
    }
    await OAuthTokenStore.retrieveNewTokenIfMissing()
  }

  // When the dataProvider returns an error, check if this is an authentication error
  async checkError(error) {
    let sessionAlive = true
    const status = error.status
    // Other error code (404, 500, etc): no need to log out
    if (status === 401 || status === 403) {
      try {
        sessionAlive = await this.userSessionIsAliveAtAuthServer()
      } catch (e) {
        sessionAlive = false
      }
      if (sessionAlive) {
        await OAuthTokenStore.retrieveNewTokenIfMissing()
      }
    }
    if (sessionAlive) {
      return Promise.resolve()
    } else {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: false })
    }
  }

  // Get the user's profile
  async getIdentity() {
    const response = await fetchFromAuth('/admin/users/current', { method: 'GET' })
    return this.toUserIdentity(response.json)
  }

  // Get the user permissions
  async getPermissions() {
    return Promise.resolve(
      'phone-number-users,personal-code-users,request-logs,refuelings,payment-requests,receipts,admin-users,oauth-applications,global-settings',
    )
  }

  private toUserIdentity(userData: any) {
    const identity: UserIdentity = {
      id: userData?.id,
      fullName: userData?.email,
    }

    return identity
  }

  private async userSessionIsAliveAtAuthServer() {
    // TODO: Avoid parallel requests
    const response = await fetchFromAuth('/admin/users/current', { method: 'GET' })
    return Boolean(response.json)
  }
}
