import { Chip, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { FC } from 'react'
import {
  Datagrid,
  DateTimeInput,
  FilterButton,
  FilterForm,
  ListBase,
  NumberInput,
  PublicFieldProps,
  SelectArrayInput,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  useRecordContext,
} from 'react-admin'
import { Color, StyledPre, VirsiPagination } from '../components'
import { DATAGRID_SX, tryFormat } from '../utils'

// TODO: Retrieve from API REST endpoint?
const REQUEST_LOG_SYSTEMS = [
  { id: 'mw', name: 'Mitigate Middleware (mw)' },
  { id: 'phone', name: 'Phone' },
  { id: 'oauth', name: 'OAuth' },
  { id: 'loyalty', name: 'EPS Loyalty' },
  { id: 'mpp', name: 'EPS Mobile Payment Pipeline (mpp)' },
  { id: 'spdas', name: 'SPDAS' },
  { id: 'cube-mw', name: 'CUBE Middleware (cube-mw)' },
  { id: 'ecr', name: 'Electronic Cash Register (ecr)' },
  { id: 'crm', name: 'Customer Relationship Management (crm)' },
  { id: 'bank', name: 'Bank' },
  { id: 'web', name: 'Web (virsi.lv)' },
  { id: 'charging-station', name: 'Charging Station (SET/Mobilly)' },
  { id: 'ms-dynamics', name: 'MS Dynamics 365' },
]

const HTTP_METHODS = [
  { id: 'get', name: 'Get (read)' },
  { id: 'post', name: 'Post (create or xml)' },
  { id: 'put', name: 'Put (update)' },
  { id: 'delete', name: 'Delete (delete)' },
  { id: 'patch', name: 'Patch (update)' },
  { id: 'all', name: 'All (read)' },
  { id: 'options', name: 'Options (read)' },
  { id: 'head', name: 'Head (read)' },
]

const REQUEST_LOG_STATES = [
  { id: 'started', name: 'Started' },
  { id: 'succeeded', name: 'Succeeded' },
  { id: 'failed', name: 'Failed' },
]

const HTTP_STATUSES = [
  { id: '200', name: '200 OK' },
  { id: '201', name: '201 Created' },
  { id: '204', name: '204 No Content' },
  { id: '302', name: '302 Found' },
  { id: '304', name: '304 Not Modified' },
  { id: '400', name: '400 Bad Request' },
  { id: '401', name: '401 Unauthorized' },
  { id: '403', name: '403 Forbidden' },
  { id: '404', name: '404 Not Found' },
  { id: '422', name: '422 Unprocessable Content' },
  { id: '500', name: '500 Internal Server Error' },
  { id: '502', name: '502 Bad Gateway' },
  { id: '504', name: '504 Gateway Timeout' },
  { id: '100', name: '100 Continue' },
  { id: '101', name: '101 Switching Protocols' },
  { id: '102', name: '102 Processing' },
  { id: '103', name: '103 Early Hints' },
  { id: '202', name: '202 Accepted' },
  { id: '203', name: '203 Non-Authoritative Information' },
  { id: '205', name: '205 Reset Content' },
  { id: '206', name: '206 Partial Content' },
  { id: '207', name: '207 Multi-Status' },
  { id: '208', name: '208 Already Reported' },
  { id: '226', name: '226 IM Used' },
  { id: '300', name: '300 Multiple Choices' },
  { id: '301', name: '301 Moved Permanently' },
  { id: '303', name: '303 See Other' },
  { id: '305', name: '305 Use Proxy' },
  { id: '306', name: '306 unused' },
  { id: '307', name: '307 Temporary Redirect' },
  { id: '308', name: '308 Permanent Redirect' },
  { id: '402', name: '402 Payment Required' },
  { id: '405', name: '405 Method Not Allowed' },
  { id: '406', name: '406 Not Acceptable' },
  { id: '407', name: '407 Proxy Authentication Required' },
  { id: '408', name: '408 Request Timeout' },
  { id: '409', name: '409 Conflict' },
  { id: '410', name: '410 Gone' },
  { id: '411', name: '411 Length Required' },
  { id: '412', name: '412 Precondition Failed' },
  { id: '413', name: '413 Payload Too Large' },
  { id: '414', name: '414 URI Too Long' },
  { id: '415', name: '415 Unsupported Media Type' },
  { id: '416', name: '416 Range Not Satisfiable' },
  { id: '417', name: '417 Expectation Failed' },
  { id: '418', name: '418 I am a teapot' },
  { id: '421', name: '421 Misdirected Request' },
  { id: '423', name: '423 Locked' },
  { id: '424', name: '424 Failed Dependency' },
  { id: '425', name: '425 Too Early' },
  { id: '426', name: '426 Upgrade Required' },
  { id: '428', name: '428 Precondition Required' },
  { id: '429', name: '429 Too Many Requests' },
  { id: '431', name: '431 Request Header Fields Too Large' },
  { id: '451', name: '451 Unavailable For Legal Reasons' },
  { id: '501', name: '501 Not Implemented' },
  { id: '503', name: '503 Service Unavailable' },
  { id: '505', name: '505 HTTP Version Not Supported' },
  { id: '506', name: '506 Variant Also Negotiates' },
  { id: '507', name: '507 Insufficient Storage' },
  { id: '508', name: '508 Loop Detected' },
  { id: '510', name: '510 Not Extended' },
  { id: '511', name: '511 Network Authentication Required' },
]

/* eslint-disable react/jsx-key */
const logsFilters = [
  <NumberInput source="id" />,
  <NumberInput source="id$gte" label="ID from" />,
  <NumberInput source="id$lte" label="ID till" />,
  <DateTimeInput source="startedAt$gte" label="Started at from" />,
  <DateTimeInput source="startedAt$lte" label="Started at till" />,
  <SelectInput source="source$eq" choices={REQUEST_LOG_SYSTEMS} label="Source" />,
  <SelectInput source="target$eq" choices={REQUEST_LOG_SYSTEMS} label="Target" />,
  <SelectArrayInput source="httpMethod$in" choices={HTTP_METHODS} label="HTTP method" />,
  <TextInput source="url" />,
  <SelectArrayInput source="state$in" choices={REQUEST_LOG_STATES} label="State" />,
  <TextInput source="error$contL" label="Error" />,
  <TextInput source="sourceIp$cont" label="Source IP" />,
  <TextInput source="requestBody$contL" label="Request body" />,
  <TextInput source="responseBody$contL" label="Response body" />,
  <SelectArrayInput source="responseStatus$in" choices={HTTP_STATUSES} label="HTTP status" />,
  <TextInput source="filterableAttributes$cont" label="Filterable" />,
  <TextInput source="filterableAttributesPhoneUserId$eq" label="User ID" />,
  <TextInput source="filterableAttributesRefuelingId$eq" label="Refueling ID" />,
  <TextInput source="filterableAttributesPaymentRequestId$eq" label="Charging Payment Request ID" />,
  <TextInput source="filterableAttributesReceiptId$eq" label="Charging Receipt ID" />,
]
/* eslint-enable react/jsx-key */

const ListToolbar = () => (
  <TopToolbar sx={{ alignItems: 'center' }}>
    <FilterForm filters={logsFilters} />
    <FilterButton filters={logsFilters} />
  </TopToolbar>
)

const LogDirectionField: FC<PublicFieldProps> = () => {
  const record = useRecordContext()
  if (!record) {
    return null
  }
  let source = record.source
  let target = record.target
  if (source === 'mw') {
    source = <strong>{source}</strong>
  }
  if (target === 'mw') {
    target = <strong>{target}</strong>
  }
  return (
    <span>
      {source} ➜ {target}
    </span>
  )
}

const STATE_COLOR_MAP: { [key: string]: Color } = {
  succeeded: 'success',
  failed: 'error',
}

const StateField: FC<PublicFieldProps> = () => {
  const record = useRecordContext()
  if (!record) {
    return null
  }
  const state = record.state
  const color: Color = STATE_COLOR_MAP[state] || 'default'
  return <Chip color={color} label={`${state} / ${record.responseStatus ?? '?'}`} size="small" variant="outlined" />
}

const HttpMethodField: FC<PublicFieldProps> = () => {
  const record = useRecordContext()
  if (!record) {
    return null
  }
  let color: Color = 'default'
  if (record.httpMethod === 'post' || record.httpMethod === 'patch' || record.httpMethod === 'put') {
    color = 'warning'
  } else if (record.httpMethod === 'delete') {
    color = 'error'
  }
  return <Chip label={record.httpMethod} color={color} size="small" variant="outlined" />
}

const TimeField: FC<PublicFieldProps> = () => {
  const record = useRecordContext()
  if (!record) {
    return null
  }
  const start = dayjs(record.startedAt)
  const complete = dayjs(record.completedAt)
  const elapsed = complete.diff(start)
  return (
    <span>
      {start.format('DD.MM.YYYY HH:mm:ss')} {elapsed}ms
    </span>
  )
}

const DataBlock = ({ label, data }: { label: string; data: string }) => {
  if (!data) {
    return null
  }
  const formattedData = tryFormat(data)
  return (
    <div>
      <Typography variant="subtitle2" component="div" style={{ fontWeight: 700 }}>
        {label}
      </Typography>
      <StyledPre>{formattedData}</StyledPre>
    </div>
  )
}

const Details = () => {
  const record = useRecordContext()
  if (!record) {
    return null
  }
  return (
    <>
      <DataBlock label="Filterable" data={JSON.stringify(record.filterableAttributes)} />
      <DataBlock label="Request" data={record.requestBody} />
      <DataBlock label="Response" data={record.responseBody} />
    </>
  )
}

export const RequestLogsList = () => (
  <ListBase
    sort={{ field: 'id', order: 'DESC' }}
    filterDefaultValues={{ ['startedAt$gte']: dayjs().subtract(1, 'day').toISOString() }}
  >
    <ListToolbar />
    <Datagrid expand={<Details />} rowClick="expand" bulkActionButtons={false} sx={DATAGRID_SX}>
      <TextField source="id" />
      <TimeField source="startedAt" />
      <LogDirectionField label="Direction" />
      <HttpMethodField source="httpMethod" />
      <TextField source="url" />
      <StateField source="state" />
      <TextField source="error" color="error" />
      <TextField source="sourceIp" />
    </Datagrid>
    <VirsiPagination />
  </ListBase>
)
