import {
  DateTimeInput,
  FilterButton,
  FilterForm,
  ListBase,
  TextField,
  TextInput,
  DateField,
  Datagrid,
  TopToolbar,
  NumberInput,
} from 'react-admin'
import { VirsiPagination } from '../components'
import { DATAGRID_SX, validateIsGuid } from '../utils'

/* eslint-disable react/jsx-key */
const UsersFilters = [
  <TextInput source="id$eq" label="ID" validate={validateIsGuid} />,
  <TextInput source="phone$cont" label="Phone" />,
  <DateTimeInput source="lockedAt$gte" label="Created at From" />,
  <DateTimeInput source="lockedAt$lte" label="Created at Till" />,
  <NumberInput source="failedAttempts$gte" label="Failed Attempts From" />,
  <NumberInput source="failedAttempts$lte" label="Failed Attempts Till" />,
  <DateTimeInput source="smsCodeSentAt$gte" label="Sms Code Sent at From" />,
  <DateTimeInput source="smsCodeSentAt$lte" label="Sms Code Sent at Till" />,
  <NumberInput source="signInCount$gte" label="Sign In Count From" />,
  <NumberInput source="signInCount$lte" label="Sign In Count Till" />,
  <DateTimeInput source="currentSignInAt$gte" label="Current Sign In at From" />,
  <DateTimeInput source="currentSignInAt$lte" label="Current Sign In at Till" />,
  <DateTimeInput source="lastSignInAt$gte" label="Last Sign In at From" />,
  <DateTimeInput source="lastSignInAt$lte" label="Last Sign In at Till" />,
  <DateTimeInput source="mobileLoyaltyTokenRetrievedAt$gte" label="Mobile Loyalty Token Retrieved at From" />,
  <DateTimeInput source="mobileLoyaltyTokenRetrievedAt$lte" label="Mobile Loyalty Token Retrieved at Till" />,
  <DateTimeInput source="webLoyaltyTokenRetrievedAt$gte" label="Web Loyalty Token Retrieved at From" />,
  <DateTimeInput source="webLoyaltyTokenRetrievedAt$lte" label="Web Loyalty Token Retrieved at Till" />,
  <DateTimeInput source="createdAt$gte" label="Created at From" />,
  <DateTimeInput source="createdAt$lte" label="Created at Till" />,
  <DateTimeInput source="updatedAt$gte" label="Updated at From" />,
  <DateTimeInput source="updatedAt$lte" label="Updated at Till" />,
]
/* eslint-enable react/jsx-key */

const ListToolbar = () => (
  <TopToolbar sx={{ alignItems: 'center' }}>
    <FilterForm filters={UsersFilters} />
    <FilterButton filters={UsersFilters} />
  </TopToolbar>
)

export const PhoneNumberUserList = () => (
  <ListBase sort={{ field: 'createdAt', order: 'DESC' }}>
    <ListToolbar />
    <Datagrid rowClick="show" bulkActionButtons={false} sx={DATAGRID_SX}>
      <TextField source="id" />
      <TextField source="phone" sortable />
      <DateField source="lockedAt" showTime />
      <TextField source="failedAttempts" />
      <DateField source="smsCodeSentAt" showTime />
      <TextField source="signInCount" />
      <DateField source="currentSignInAt" showTime />
      <DateField source="lastSignInAt" showTime />
      <DateField source="mobileLoyaltyTokenRetrievedAt" showTime />
      <DateField source="webLoyaltyTokenRetrievedAt" showTime />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
    <VirsiPagination />
  </ListBase>
)
