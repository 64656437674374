import { Create, email, PasswordInput, required, SimpleForm, TextInput } from 'react-admin'
import { useCreateWithServerValidationMapping } from '../utils'

const validateEmail = [required(), email()]

export const AdminUserCreate = () => {
  const create = useCreateWithServerValidationMapping('admin-users')
  return (
    <Create>
      <SimpleForm onSubmit={create}>
        <TextInput source="email" validate={validateEmail} fullWidth />
        <PasswordInput source="password" validate={required()} fullWidth autoComplete="new-password" />
        <PasswordInput source="passwordConfirmation" validate={required()} fullWidth />
      </SimpleForm>
    </Create>
  )
}
