import { defaultTheme } from 'react-admin'

export const virsiTheme = {
  palette: {
    primary: {
      main: '#0C306E',
    },
    secondary: {
      main: '#FFFFF',
    },
  },
  components: {
    ...defaultTheme.components,
    // TODO: Make menu blue:
    // RaMenuItemLink: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: '#0C306E',
    //       '& :active': {
    //         backgroundColor: 'red',
    //       },
    //     },
    //   },
    // },
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          color: '#FFFFFF',
          backgroundColor: '#0C306E',
        },
      },
    },
  },
}
