import {
  DateTimeInput,
  FilterButton,
  FilterForm,
  ListBase,
  NumberInput,
  TextField,
  TextInput,
  DateField,
  Datagrid,
  CreateButton,
  TopToolbar,
  DeleteButton,
} from 'react-admin'
import { VirsiPagination } from '../components'
import { DATAGRID_SX, validateIsGuid } from '../utils'

/* eslint-disable react/jsx-key */
const RefuelingFilters = [
  <TextInput source="id$eq" label="ID" validate={validateIsGuid} />,
  <TextInput source="email" />,
  <NumberInput source="signInCount$gte" label="Signed in count from" />,
  <NumberInput source="signInCount$lte" label="Signed in count till" />,
  <DateTimeInput source="createdAt$gte" label="Created from" />,
  <DateTimeInput source="createdAt$lte" label="Created till" />,
]
/* eslint-enable react/jsx-key */

const ListToolbar = () => (
  <TopToolbar sx={{ alignItems: 'center' }}>
    <FilterForm filters={RefuelingFilters} />
    <div>
      <CreateButton />
    </div>
    <FilterButton filters={RefuelingFilters} />
  </TopToolbar>
)

export const AdminUserList = () => (
  <ListBase sort={{ field: 'createdAt', order: 'DESC' }}>
    <ListToolbar />
    <Datagrid rowClick="show" bulkActionButtons={false} sx={DATAGRID_SX}>
      <TextField source="id" />
      <TextField source="email" />
      <TextField source="signInCount" />
      <TextField source="lastSignInIp" />
      <DateField source="lastSignInAt" showTime />
      <TextField source="currentSignInIp" />
      <DateField source="currentSignInAt" showTime />
      <TextField source="failedAttempts" />
      <DateField source="lockedAt" showTime />
      <DateField source="resetPasswordSentAt" showTime />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
      <DeleteButton mutationMode="pessimistic" />
    </Datagrid>
    <VirsiPagination />
  </ListBase>
)
