import { fetchUtils, Options } from 'react-admin'
import CsrfStore from './CsrfStore'

export const fetchFromAuth = async (pathOrUrl, options?: Options) => {
  const headers = new Headers(options?.headers ?? {})
  headers.has('Accept') || headers.set('Accept', 'application/json')
  headers.has('Accept-Language') || headers.set('Accept-Language', 'en')
  if (options?.method && options.method !== 'GET') {
    headers.has('X-CSRF-Token') || headers.set('X-CSRF-Token', await CsrfStore.getToken())
  }

  const url = pathOrUrl.startsWith('http') ? pathOrUrl : `${process.env.REACT_APP_AUTH_URL}${pathOrUrl}`
  return fetchUtils.fetchJson(url, { ...options, headers, credentials: 'include' })
}

export type OAuthTokenData = {
  value: string
  expiresAt: string
}
