import { Edit, SelectInput, SimpleForm, required, useNotify, useRefresh } from 'react-admin'
import { EDITABLE_REFUELING_STATES } from './utils'
import { buildErrorMessage } from '../utils'
import { EditToolbarWithoutDeleteButton } from '../components'

const transform = (data) => ({
  state: data.state,
})

export const RefuelingEdit = () => {
  const notify = useNotify()
  const refresh = useRefresh()

  const onError = (error) => {
    notify(buildErrorMessage(error), { type: 'error' })
    refresh()
  }

  return (
    <Edit mutationMode="pessimistic" transform={transform} mutationOptions={{ onError }} redirect="show">
      <SimpleForm toolbar={<EditToolbarWithoutDeleteButton />}>
        <SelectInput source="state" choices={EDITABLE_REFUELING_STATES} validate={required()} />
      </SimpleForm>
    </Edit>
  )
}
