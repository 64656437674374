import { DateField, EmailField, Show, SimpleShowLayout, TextField } from 'react-admin'

export const AdminUserShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <EmailField source="email" />
      <TextField source="signInCount" />
      <TextField source="lastSignInIp" />
      <DateField source="lastSignInAt" showTime />
      <TextField source="currentSignInIp" />
      <DateField source="currentSignInAt" showTime />
      <TextField source="failedAttempts" />
      <DateField source="lockedAt" showTime />
      <DateField source="resetPasswordSentAt" showTime />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </SimpleShowLayout>
  </Show>
)
