import { useRecordContext } from 'react-admin'
import { Typography } from '@mui/material'

export const StationNameShowField = (props: { source: string; label?: string }) => {
  const [stationField, translationsField] = props.source.split('.')
  const record = useRecordContext(props)
  const station = record[stationField]
  return station ? (
    <Typography variant="body2">{Object.values(station[translationsField]).join(' / ')}</Typography>
  ) : null
}
