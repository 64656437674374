import {
  TextField,
  DateField,
  Show,
  SimpleShowLayout,
  NumberField,
  TopToolbar,
  useRecordContext,
  EditButton,
} from 'react-admin'
import { LinkToRecordRequestLogs, ReceiptTextShowField, StationNameShowField } from '../components'
import { isEditable } from './utils'

const PaymentRequestShowActions = () => {
  const paymentRequest = useRecordContext()
  const button = paymentRequest && isEditable(paymentRequest) ? <EditButton /> : null
  return <TopToolbar>{button}</TopToolbar>
}

export const PaymentRequestShow = () => (
  <Show actions={<PaymentRequestShowActions />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="externalId" label="External ID" />
      <TextField source="state" />
      <TextField source="gasStationNfCode" label="Gas Station NF Code" />
      <StationNameShowField source="gasStation.nameTranslation" label="Gas Station Name" />
      <TextField source="chargingProvider" />
      <TextField source="chargingStationId" />
      <TextField source="connectorId" />
      <TextField source="ecrCode" />
      <TextField source="productCode" />
      <TextField source="connectorType" />
      <TextField source="connectorPower" />
      <DateField source="beginAt" showTime />
      <DateField source="endAt" showTime />
      <TextField source="chargingSessionId" />
      <NumberField source="quantity" />
      <NumberField source="pricePerUnitInCents" />
      <NumberField source="amountInCents" />
      <TextField source="lockedByEcrCode" />
      <DateField source="lockedAt" showTime />
      <TextField source="paidBy" />
      <NumberField source="paidAmountInCents" />
      <NumberField source="discountAmountInCents" />
      <NumberField source="vatAmountInCents" />
      <TextField source="receiptNumber" />
      <ReceiptTextShowField source="receiptText" />
      <TextField source="lastErrorCode" />
      <TextField source="lastErrorMessage" />
      <DateField source="lastErrorAt" showTime />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
      <TextField source="manuallyEditedBy" />
      <LinkToRecordRequestLogs filterName="PaymentRequest" />
    </SimpleShowLayout>
  </Show>
)
