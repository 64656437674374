import './App.css'
import { Admin, CustomRoutes, Resource } from 'react-admin'
import { Route } from 'react-router-dom'
import UserIcon from '@mui/icons-material/People'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import SecurityIcon from '@mui/icons-material/Security'
import SettingsIcon from '@mui/icons-material/Settings'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import ReceiptIcon from '@mui/icons-material/Receipt'
import { virsiTheme } from './layout/themes'
import VirsiLayout from './layout/VirsiLayout'
import { RequestLogsList } from './requestLogs'
import { PhoneNumberUserList, PhoneNumberUserShow } from './phoneNumberUsers'
import { virsiCrudProvider } from './dataProvider'
import { RefuelingShow, RefuelingList, RefuelingEdit } from './refueling'
import { PaymentRequestShow, PaymentRequestList, PaymentRequestEdit } from './paymentRequests'
import { VirsiAuthProvider } from './authProvider'
import { AdminUserCreate, AdminUserList, AdminUserShow } from './adminUsers'
import { CurrentUserPasswordChange } from './currentUser'
import { VirsiLoginPage } from './components'
import { OAuthApplicationList, OAuthApplicationShow } from './oAuthApplications'
import { PersonalCodeUserList, PersonalCodeUserShow } from './personalCodeUsers'
import { GlobalSettingEdit, GlobalSettingList, GlobalSettingShow } from './globalSettings'
import { ReceiptList, ReceiptShow, ReceiptEdit } from './receipts'

const App = () => (
  <Admin
    dataProvider={virsiCrudProvider}
    authProvider={new VirsiAuthProvider()}
    requireAuth
    layout={VirsiLayout}
    theme={virsiTheme}
    loginPage={VirsiLoginPage}
  >
    {(permissions) => (
      <>
        {permissions.includes('phone-number-users') ? (
          <Resource
            name="phone-number-users"
            list={PhoneNumberUserList}
            show={PhoneNumberUserShow}
            icon={PhoneAndroidIcon}
            options={{ label: 'Phone users' }}
            recordRepresentation={(record) => `${record.phone} ${record.id}`}
          />
        ) : null}
        {permissions.includes('personal-code-users') ? (
          <Resource
            name="personal-code-users"
            list={PersonalCodeUserList}
            show={PersonalCodeUserShow}
            icon={AccountBalanceIcon}
            options={{ label: 'Personal code users' }}
          />
        ) : null}
        {permissions.includes('request-logs') ? (
          <Resource name="request-logs" list={RequestLogsList} options={{ label: 'Request logs' }} />
        ) : null}
        {permissions.includes('refuelings') ? (
          <Resource
            name="refueling"
            list={RefuelingList}
            icon={LocalGasStationIcon}
            show={RefuelingShow}
            edit={RefuelingEdit}
            options={{ label: 'Refuelings' }}
          />
        ) : null}
        {permissions.includes('payment-requests') ? (
          <Resource
            name="payment-requests"
            list={PaymentRequestList}
            icon={AccountBalanceWalletIcon}
            show={PaymentRequestShow}
            edit={PaymentRequestEdit}
            options={{ label: 'Charging payments' }}
          />
        ) : null}
        {permissions.includes('receipts') ? (
          <Resource
            name="receipts"
            list={ReceiptList}
            icon={ReceiptIcon}
            show={ReceiptShow}
            edit={ReceiptEdit}
            options={{ label: 'Charging receipts' }}
          />
        ) : null}
        {permissions.includes('oauth-applications') ? (
          <Resource
            name="oauth-applications"
            list={OAuthApplicationList}
            show={OAuthApplicationShow}
            icon={SecurityIcon}
            options={{ label: 'OAuth apps' }}
          />
        ) : null}
        {permissions.includes('global-settings') ? (
          <Resource
            name="global-settings"
            list={GlobalSettingList}
            show={GlobalSettingShow}
            edit={GlobalSettingEdit}
            icon={SettingsIcon}
            options={{ label: 'Settings' }}
          />
        ) : null}
        {permissions.includes('admin-users') ? (
          <Resource
            name="admin-users"
            list={AdminUserList}
            show={AdminUserShow}
            create={AdminUserCreate}
            icon={UserIcon}
            options={{ label: 'Admins' }}
          />
        ) : null}
        <CustomRoutes>
          <Route path="/change-password" element={<CurrentUserPasswordChange />} />
        </CustomRoutes>
      </>
    )}
  </Admin>
)

export default App
