import {
  DateTimeInput,
  FilterButton,
  FilterForm,
  ListBase,
  NumberInput,
  TextField,
  TextInput,
  DateField,
  Datagrid,
  FunctionField,
  TopToolbar,
  NumberField,
  SelectArrayInput,
  ExportButton,
  PublicFieldProps,
  useRecordContext,
  EditButton,
} from 'react-admin'
import { Color, VirsiPagination } from '../components'
import { DATAGRID_SX, pickTranslation, validateIsGuid } from '../utils'
import { PAYMENT_REQUEST_STATES, isEditable } from './utils'
import { FC } from 'react'
import { Chip } from '@mui/material'

/* eslint-disable react/jsx-key */
const RefuelingFilters = [
  <NumberInput source="id" label="Internal ID" />,
  <TextInput source="externalId$eq" label="External ID" validate={validateIsGuid} />,
  <SelectArrayInput source="state$in" choices={PAYMENT_REQUEST_STATES} label="State" />,
  <TextInput source="gasStationNfCode" label="Gas Station NF Code" />,
  <DateTimeInput source="createdAt$gte" label="Created From" />,
  <DateTimeInput source="createdAt$lte" label="Created Till" />,
  <TextInput source="productCode" />,
  <TextInput source="paidBy$cont" label="Paid By" />,
  <TextInput source="receiptNumber$cont" label="Receipt Number" />,
  <TextInput source="lastErrorMessage$cont" label="Last Error Message" />,
  <NumberInput source="lastErrorCode" label="Last Error Code" />,
  <DateTimeInput source="lastErrorAt$gte" label="Last Error From" />,
  <DateTimeInput source="lastErrorAt$lte" label="Last Error Till" />,
  <NumberInput source="amountInCents$gte" label="Amount From" />,
  <NumberInput source="amountInCents$lte" label="Amount Till" />,
]
/* eslint-enable react/jsx-key */

const STATE_COLOR_MAP: { [key: string]: Color } = {
  new: 'primary',
  'locked-by-ecr': 'warning',
  failed: 'error',
  'final-succeeded': 'success',
  'final-canceled': 'error',
  'final-uncharged': 'default',
  'must-handle-manually': 'error',
}

const StateField: FC<PublicFieldProps> = () => {
  const record = useRecordContext()
  if (!record) {
    return null
  }
  const state = record.state
  const color: Color = STATE_COLOR_MAP[state] || 'default'
  const label = PAYMENT_REQUEST_STATES.find((s) => s.id === state)?.name || state
  return <Chip color={color} label={label} size="small" variant="outlined" />
}

const ListToolbar = () => (
  <TopToolbar sx={{ alignItems: 'center' }}>
    <FilterForm filters={RefuelingFilters} />
    <FilterButton filters={RefuelingFilters} />
    <ExportButton />
  </TopToolbar>
)

export const PaymentRequestList = () => (
  <ListBase sort={{ field: 'id', order: 'DESC' }}>
    <ListToolbar />
    <Datagrid rowClick="show" bulkActionButtons={false} sx={DATAGRID_SX}>
      <TextField source="id" />
      <TextField source="externalId" label="External ID" />
      <StateField source="state" />
      <FunctionField
        label="Gas Station"
        source="gasStation.nameTranslation"
        sortable={false}
        render={(record) =>
          record.gasStation
            ? `${pickTranslation(record.gasStation.nameTranslation)} (${record.gasStation.nfCode})`
            : record.gasStationNfCode
        }
      />
      <TextField source="chargingStationId" />
      <TextField source="connectorId" />
      <TextField source="chargingSessionId" />
      <TextField source="productCode" />
      <TextField source="lastErrorCode" />
      <TextField source="lastErrorMessage" />
      <NumberField source="quantity" />
      <NumberField source="pricePerUnitInCents" />
      <NumberField source="amountInCents" />
      <TextField source="paidBy" />
      <NumberField source="paidAmountInCents" />
      <NumberField source="discountAmountInCents" />
      <TextField source="receiptNumber" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
      <FunctionField
        label=""
        render={(record) => {
          if (isEditable(record)) {
            return <EditButton />
          } else {
            return null
          }
        }}
      />
    </Datagrid>
    <VirsiPagination />
  </ListBase>
)
