import dayjs from 'dayjs'
import { stringify } from 'qs'
import { fetchFromAuth, OAuthTokenData } from './utils'

const OAUTH_NO_REDIRECT_URI = 'urn:ietf:wg:oauth:2.0:oob'
const OAUTH_VERIFIER_LENGTH = 64

export default class OAuthService {
  private codeVerifier: string

  constructor() {
    this.codeVerifier = this.generateCodeVerifier()
  }

  async retrieveToken() {
    const code = await this.fetchCodeInOAuthAuthorizationCodeFlow()
    const tokenResponse = await this.fetchTokenInOAuthAuthorizationCodeFlow(code)

    const tokenData: OAuthTokenData = {
      value: tokenResponse.access_token,
      expiresAt: dayjs()
        .add(tokenResponse.expires_in - 10, 'seconds')
        .toISOString(),
    }
    return tokenData
  }

  private generateCodeVerifier() {
    const randomByteArray = crypto.getRandomValues(new Uint8Array(OAUTH_VERIFIER_LENGTH))
    const randomString = this.byteArrayToString(randomByteArray)
    return this.encodeToUri(randomString)
  }

  private async generateCodeChallenge() {
    const codeVerifierByteArray = new Uint8Array(this.codeVerifier.length)
    for (let i = 0; i < this.codeVerifier.length; i++) {
      codeVerifierByteArray[i] = this.codeVerifier.charCodeAt(i)
    }
    const digest = await crypto.subtle.digest('SHA-256', codeVerifierByteArray)
    const challengeString = this.byteArrayToString(new Uint8Array(digest))
    return this.encodeToUri(challengeString)
  }

  private byteArrayToString(byteArray: Uint8Array) {
    return Array.prototype.map.call(byteArray, (number) => String.fromCharCode(number)).join('')
  }

  private encodeToUri(string: string) {
    // https://tools.ietf.org/html/rfc4648#section-5
    return btoa(string).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '').substring(0, OAUTH_VERIFIER_LENGTH)
  }

  private async fetchCodeInOAuthAuthorizationCodeFlow() {
    const params = {
      response_type: 'code',
      client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
      redirect_uri: OAUTH_NO_REDIRECT_URI,
      code_challenge: await this.generateCodeChallenge(),
      code_challenge_method: 'S256',
      user_type: 'admin',
      // This option prevents redirect.
      // Redirect would fail because of missing CORS headers. And JS can't read redirect url.
      response_mode: 'form_post',
    }

    const response = await fetchFromAuth(`/oauth/v1/authorize?${stringify(params)}`, {
      method: 'GET',
    })
    const code = response.json?.code
    if (!code) {
      throw new Error(`Expected OAuth server to return a code, but got response: ${response.body}`)
    }
    return code
  }

  private async fetchTokenInOAuthAuthorizationCodeFlow(code: string) {
    const response = await fetchFromAuth('/oauth/v1/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: stringify({
        grant_type: 'authorization_code',
        code,
        code_verifier: this.codeVerifier,
        client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
        client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
        redirect_uri: OAUTH_NO_REDIRECT_URI,
      }),
    })
    return response.json
  }
}
