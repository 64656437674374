import { Typography } from '@mui/material'
import { PublicFieldProps, useRecordContext } from 'react-admin'

const masquerade = (value: string, mask: string) => {
  if (!value) {
    return value
  }

  const result = []
  let i = 0
  let char: string
  while (i < value.length) {
    if (mask[i] === '.' || mask[i] === undefined) {
      char = value[i]
    } else {
      char = mask[i]
    }
    result.push(char as never)
    i++
  }
  return result.join('')
}

export interface MaskedFieldProps extends PublicFieldProps {
  mask: string
}

export const MaskedField = (props: MaskedFieldProps) => {
  const record = useRecordContext()
  if (!record || !props.source) {
    return null
  }
  let value = record[props.source]

  if (props.mask) {
    value = masquerade(value, props.mask)
  }

  return (
    <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
      {value}
    </Typography>
  )
}

export const PERSONAL_CODE_MASK = '..***..***..'
