import { Create, required, SimpleForm, PasswordInput } from 'react-admin'
import { useCreateWithServerValidationMapping } from '../utils'

export const CurrentUserPasswordChange = () => {
  const save = useCreateWithServerValidationMapping('users/current', 'Password changed successfully', '/')
  return (
    <Create resource="users/current" title="Change password">
      <SimpleForm onSubmit={save}>
        <PasswordInput source="currentPassword" validate={required()} fullWidth />
        <PasswordInput source="password" label="New password" validate={required()} fullWidth />
        <PasswordInput source="passwordConfirmation" validate={required()} fullWidth />
      </SimpleForm>
    </Create>
  )
}
