import { useCallback } from 'react'
import { HttpError, regex, useCreate, useNotify, useRedirect } from 'react-admin'
import format from 'xml-formatter'

export const DATAGRID_SX = { '@media(min-width: 600px)': { maxWidth: 'calc(100vw - 280px)', overflowX: 'auto' } }

export const tryFormat = (jsonOrXml: string) => {
  try {
    if (!jsonOrXml) {
      return jsonOrXml
    }
    if (jsonOrXml.startsWith('{') || jsonOrXml.startsWith('[')) {
      return JSON.stringify(JSON.parse(jsonOrXml), null, 2)
    }
    if (jsonOrXml.startsWith('<')) {
      return format(jsonOrXml)
    }
    return jsonOrXml
  } catch (e) {
    // TODO: Sentry
    // eslint-disable-next-line no-console
    console.warn('Error occurred while formatting request/response jsonOrXml', e, jsonOrXml)
    return jsonOrXml
  }
}

export const validateIsGuid = regex(
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi,
)

export const useCreateWithServerValidationMapping = (
  resource: string,
  successMessage?: string | null,
  redirectPath?: string | null,
) => {
  const [create] = useCreate()
  const notify = useNotify()
  const redirect = useRedirect()
  const save = useCallback(
    async (values) => {
      try {
        const responseBody = await create(resource, { data: values }, { returnPromise: true })
        // TODO: Call onSuccess and onError callbacks from <Create> properly.
        // And then show this message & redirect at:
        // <Create mutationOptions={{ onSuccess, onError }} ...>
        notify(successMessage || 'Record created successfully', { type: 'success' })
        if (redirectPath) {
          redirect(redirectPath)
        } else {
          // TODO: Make <SimpleForm warnWhenUnsavedChanges> work with this redirect.
          redirect('show', resource, responseBody?.id)
        }
      } catch (error) {
        if (error instanceof HttpError && error.body?.errors) {
          notify('The form is not valid. Please check for errors', { type: 'error' })
          return error.body.errors
        } else {
          notify((error as any).message, { type: 'error' })
        }
      }
    },
    [create, notify, redirect],
  )
  return save
}

export const pickTranslation = (translatedField) => {
  return translatedField?.en || translatedField?.lv || translatedField?.ru
}

export const buildErrorMessage = (exception) => {
  if (exception instanceof HttpError) {
    const userMessage = (exception as HttpError).body?.error?.userFriendlyMessage
    if (userMessage) {
      return userMessage
    }
  }
  return exception.message
}
