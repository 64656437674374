import { fetchUtils } from 'react-admin'

const CSRF_STORAGE_KEY = 'virsi-csrf-token'

export default class CsrfStore {
  static async getToken() {
    let csrf = sessionStorage.getItem(CSRF_STORAGE_KEY)
    if (!csrf) {
      const response = await fetchUtils.fetchJson(`${process.env.REACT_APP_AUTH_URL}/admin/users/csrf`, {
        credentials: 'include',
      })
      csrf = response.json.csrf as string
      sessionStorage.setItem(CSRF_STORAGE_KEY, csrf)
    }
    return csrf
  }

  static clear() {
    sessionStorage.removeItem(CSRF_STORAGE_KEY)
  }
}
